import { Row } from 'react-bootstrap';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Link from 'ui-kit/link/link';
import './forgot-username-nav.style.scss';

const ForgotUsernameNav = () => {
    const { t } = useTranslation();

    return (
        <Row className={'d-flex flex-row justify-content-between wrapper'}>
            <Link
                className="d-inline-block back-button"
                to="/login-issues"
                label={t('pages.profile.messages.labels.back')}
                variant="site"
                dataGALocation="loginIssues"
            />
            <Link
                className="d-inline-block"
                to="/sign-in"
                label={t('pages.profile.messages.labels.cancel')}
                variant="primary"
                dataGALocation="loginIssues"
            />
        </Row>
    );
};

export default ForgotUsernameNav;
