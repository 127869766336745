import React, { ReactElement } from 'react';
import { Form, Formik } from 'formik';
import { TFunction, useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, Row, Col } from 'react-bootstrap';

import { FORGOT_USERNAME_SCHEMA } from 'schema/forgot-username';

import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import Button from 'ui-kit/button/button';
import Text from 'ui-kit/text/text';

import { ForgotUsernameFormValues, ForgotUsernameProps } from './forgot-username-form.props';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';

export const ForgotUsernameSuccessModalContent = ({ translation }: { translation: TFunction<'translation'> }) => {
    return (
        <BirdiModalContent
            icon="default"
            title={translation('modals.forgotUsernameSuccess.title')}
            body={translation('modals.forgotUsernameSuccess.body')}
        />
    );
};

export const ForgotUsernameFailureModalHeader = ({ translation }: { translation: TFunction<'translation'> }) => {
    return <BirdiModalHeaderDanger headerText={translation('modals.forgotUsernameFailure.title')} icon="alert" />;
};

export const ForgotUsernameFailureModalContent = ({ translation }: { translation: TFunction<'translation'> }) => {
    return <BirdiModalContentAlt subTitle={translation('modals.forgotUsernameFailure.body')} />;
};

const ForgotUsernameForm = ({ isSubmittingUsernameRequest, onSubmit, style }: ForgotUsernameProps): ReactElement => {
    const { t } = useTranslation();
    const formName = 'ForgotUsernameForm';
    const handleSubmit = (values: Partial<ForgotUsernameFormValues>) => {
        onSubmit(values);
    };

    return (
        <Container fluid style={style}>
            <Row>
                <Col className="d-flex flex-column align-items-center">
                    <p>{t('forgotUsername.labels.formInstructions')}</p>
                    <Formik<Partial<ForgotUsernameFormValues>>
                        onSubmit={handleSubmit}
                        validationSchema={FORGOT_USERNAME_SCHEMA}
                        initialValues={{
                            email: undefined
                        }}
                    >
                        {(formik: any) => (
                            <Form
                                id="forgot-username-form"
                                data-ga-form-name={formName}
                                onSubmit={formik.handleSubmit}
                                autoComplete="off"
                            >
                                <Text
                                    label={t('forgotUsername.labels.email')}
                                    name="email"
                                    onChange={formik.handleChange}
                                    errors={formik.errors?.email ? t('forgotUsername.errors.username') : undefined}
                                    touched={formik.touched.email}
                                    value={formik.values?.email}
                                    defaultValue={formik.initialValues?.email}
                                    maxLength={255}
                                    type="email"
                                />
                                <div className="d-flex flex-column align-items-center">
                                    <Button
                                        async
                                        className="w-100"
                                        label={t('forgotUsername.labels.submit')}
                                        type="submit"
                                        isBusy={isSubmittingUsernameRequest}
                                        dataGAFormName={formName}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

export default ForgotUsernameForm;
